<template>
  <b-card class="shop-store-reviews">
    <b-row>
      <b-col cols="12">
        <good-data-table
          ref="overviewRef"
          :button-label="$t('Add Overview')"
          :columns="overviewColumns"
          :total-column="'response.responseData.total'"
          :data-column="'response.responseData.data'"
          :api-endpoint="getApiEndpoint"
          :modify-data="modifyData"
          @add-new-info="showModal"
        />
      </b-col>
    </b-row>
    <b-modal
      v-model="showOverViewModal"
      :no-close-on-backdrop="true"
      :title="activeOvewview && activeOvewview.id ? 'Edit Ovewview' : 'Add Ovewview'"
      ok-only
      size="xl"
      :ok-title="$t('Save Changes')"
      @hidden="showOverViewModal = false"
      @ok="saveOverviews"
    >
      <b-row>
        <b-col
          cols="12"
          md="12"
          class="show-toolbar-ck"
        >
          <b-form-group :label="$t('Overview')">
            <ckeditor
              v-model="activeOvewview.overview"
              :editor="editor"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BModal, BFormGroup } from 'bootstrap-vue'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { useStoreUi } from './useStore'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    GoodDataTable,
    BModal,
    BFormGroup,
  },
  data() {
    return {
      editor: ClassicEditor,
      showOverViewModal: false,
      activeOvewview: {
        store_id: '',
        overview: '',
      },
      overviewColumns: [
        {
          label: 'Overviews',
          field: 'overview',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('edit'),
              text: 'Edit',
              action: props => {
                this.editOverview(props)
              },
            },
          ],
        },
      ],
    }
  },
  computed: {
    getApiEndpoint() {
      return window.SuperApp.getters.isStore() ? '/shop/overviews' : '/provider/overviews'
    },
    isStore() {
      return window.SuperApp.getters.isStore()
    },
  },
  methods: {
    modifyData(response) {
      if (!this.isStore) {
        // eslint-disable-next-line no-param-reassign
        response.responseData = {
          data: [response.responseData],
          total: 1,
        }
      }
      return response
    },
    showModal() {
      this.showOverViewModal = true
      this.activeOvewview = {
        id: '',
        overview: '',
      }
    },
    editOverview(payload) {
      if (this.isStore) {
        this.activeOvewview = {
          id: '',
          overview: '',
        }
        const { getOvewViewById } = useStoreUi()
        showLoader()
        getOvewViewById(payload.id)
          .then(({ data }) => {
            if (data.statusCode === '200') {
              this.activeOvewview = data.responseData
              this.showOverViewModal = true
            } else {
              showDangerNotification(this, data.message)
            }
            hideLoader()
          })
          .catch(error => {
            showErrorNotification(this, error)
          })
      } else {
        this.activeOvewview = payload
        this.showOverViewModal = true
      }
    },
    saveOverviews() {
      if (!this.activeOvewview.id) {
        this.createOvewview()
      } else {
        this.updateOvewview()
      }
    },
    createOvewview() {
      const { createOverview } = useStoreUi()
      showLoader()

      const formData = new FormData()
      formData.append(this.isStore ? 'store_id' : 'provider_id', window.SuperApp.getters.userInfo().id)
      formData.append('overview', this.activeOvewview.overview)

      createOverview(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, data.message)
            this.$refs.overviewRef.loadItems()
            this.showOverViewModal = false
          } else {
            this.showOverViewModal = true
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          this.showOverViewModal = true
          showErrorNotification(this, error)
        })
    },
    updateOvewview() {
      const { editOverview } = useStoreUi()
      showLoader()

      const formData = new FormData()
      formData.append(this.isStore ? 'store_id' : 'provider_id', window.SuperApp.getters.userInfo().id)
      formData.append('overview', this.activeOvewview.overview)
      formData.append('_method', 'PATCH')

      editOverview({
        data: formData,
        query: this.activeOvewview.id,
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, data.message)
            this.showOverViewModal = false
            this.$refs.overviewRef.loadItems()
          } else {
            this.showOverViewModal = true
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          this.showOverViewModal = true
          showErrorNotification(this, error)
        })
    },
  },
}
</script>
